<template>
  <div class="w-100">
    <Navbar :pagetitle='pagetitle' :navitems="navitems" @downloadCall="showDowns=!showDowns" />
    <Download v-if="showDowns" @close="showDowns=false" />
    <Aside :head="head" :data="first" />
    <Aside :data="second" />
    <Aside :data="third" />
    <Aside :data="fourth" />
    <Aside :data="fifth" />
    <div class="empty-50" />
    <Modal v-if="showModal" @close="showModal=false" />
    <Footer  @modalCall="showModal=true" />
  </div>
</template>

<script>

import Modal    from '@/components/Modal'
import Navbar   from '@/components/Navbar'
import Footer   from '@/components/Footer'
import Download from '@/components/Downloads'
import Aside    from '@/components/ImageAsideAlt'
import Img1     from '@/assets/resized/nuez/e.png'
import Img2     from '@/assets/resized/nuez/8.png'
import Img3     from '@/assets/resized/nuez/9.png'
import Img4     from '@/assets/resized/nuez/5.png'
import Img5     from '@/assets/resized/nuez/2.png'

  export default ({
      name: 'GUI',
      components: { Aside, Download, Modal, Navbar, Footer },
      data(){ return {
        showDowns:false,
        showModal:false,
        pagetitle: 'Graphical User Interface',
        head: { title: 'GUI', description: 'A quick runthrough.' }, 
        navitems: [
            { title: 'nuez', route: 'nuez' },
            { title: 'gui',  route: 'gui' }, 
            { title: 'settings', route: 'settings' }, 
            { title: 'mongodb',  route: 'mongodb' },
        ],
        first: {
          side: 'right',
          text: [
            'there is something new in your taskbar!',
            'leftclick will open the quickmenu, ',
            'rightclick un-/hides the window.'
          ],
          image: Img1,
          glow: false
        },
        second: {
          side: 'left',
          text: [
            'the navbar on top is kept very simple. to move your window click inside it and drag.',
            'the ´home´ button works in combination with your channels.',
            '´public´ means public! everyone on your server can read these messages, which are encrypted with a fixed passphrase.',
            'under ´groups´ you can create and maintain your group-page, that will show up in the right sidebar for everyone.'
          ],
          image: Img2,
          glow: true
        },
        third: {
          side: 'right',
          text: [
            'in the left sidebar your channels are placed. since nuez comes with my serverdata preset, ´nuez´ channel will already be set.',
            'to add a new channel: click on the ´+´ sign, enter the name and press the ´add´ button!',
            'to remove a channel click on the minus after your channel´s name.'
          ],
          image: Img3,
          glow: true
        },
        fourth: {
          side: 'left',
          text: [
            '´groups´ on the right hand shows you all available groups on your server.',
            'by clicking one of the buttons you will open the groups page, this time a very simple one, but i´ve taken note of it´s potential - especially when i´ve got onion running..'
            ],
          image:  Img4,
          glow: true
        },
        fifth: {
          side: 'right',
          text: [
            'to post.. really?! click inside the input-field.',
            '´red´ is for adding data: image, pdf and -even- video are enabled.. but once more: i advise against uploading videos.',
            '´green´ is for sending your post to the server.',
            'to answer a post: click inside the one you want to reply to.',
            'if there´s a numbered marble on the bottom of a post: these are the counted replies.',
            'to hide a post click the ´arrows up´ and it will be minimized.',
            'i know: quite simple, but to build even that took me a while.'
          ],
          image: Img5,
          glow: true
        },
      }}
  })
</script>

<style scoped>
</style>